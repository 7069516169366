import * as dompack from 'dompack';

import * as mso from '@mod-mso/webdesigns/mso/mso';

import '@mod-mso/webdesigns/mso/mso.scss';
import './groond.scss';

dompack.onDomReady(() => {
  new mso.MSOSite();
});
